import { ref, watch, computed } from '@vue/composition-api'
import http from '@axios'
import Swal from 'sweetalert2'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'username',
      label: 'Имя пользователь',
      sortable: true,
    },
    {
      key: 'email',
      label: 'Эл. почта',
      sortable: true,
    },
    {
      key: 'role',
      label: 'Роль',
      sortable: false,
    },
    {
      key: 'status',
      label: 'Статус',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Действие',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    const qs = require('qs');
    http
      .get('https://edu.startupchoikhona.tj/backend/api/user/index', {
        params: {
          search: searchQuery.value,
          length: perPage.value,
          page: currentPage.value,
          order: {
            column: sortBy.value,
            type: isSortDirDesc && !isSortDirDesc.value ? 'asc' : 'desc',
          },
          role_id: roleFilter.value,
          status: statusFilter.value,
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      })
      .then(response => {
        const users = response.data.data.items
        callback(users)
        totalUsers.value = response.data.data.total_rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Не удалось получить список пользователей.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteUser = (idUser) => {
    Swal.fire({
      title: 'Вы действительно хотите удалить этот пользователя?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      customClass: {
        cancelButton: 'btn btn-outline-danger ml-1',
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then(result => {
        if (result.value && idUser > 0) {
          http
            .post(`https://edu.startupchoikhona.tj/backend/api/user/delete/${idUser}`, {})
            .then(response => {
              refetchData()
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Успешно удалено.',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка при удалении пользователя',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Удаление пользователя отменено',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'mentor') return 'warning'
    if (role === 'student') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'mentor') return 'UserIcon'
    if (role === 'student') return 'UserIcon'
    if (role === 'admin') return 'UserIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 2) return 'warning'
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    deleteUser,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
